import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCUIT'
})
export class FormatCUITPipe implements PipeTransform {
  /**
   * Pipe para formatear un numero con formato de CUIT.
   * @param value El valor pasado al pipe.
   */
  transform(value: any): any {
    if (!['number', 'string'].includes(typeof value) || value.toString().length < 11) {
      return value;
    }

    const nmbrStr = value.toString();

    const cuit = [nmbrStr.slice(0, 2), '-', nmbrStr.slice(2, 10), '-', nmbrStr[10]].join('');

    return cuit;
  }
}
